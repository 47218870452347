const accountTypes = `${process.env.VUE_APP_CORPORATE_PATH}|${process.env.VUE_APP_ADMIN_PATH}|${process.env.VUE_APP_CUSTOMER_PATH}`;
var admin = process.env.VUE_APP_ADMIN_PATH;
var corporate = process.env.VUE_APP_CORPORATE_PATH;
var customer = process.env.VUE_APP_CUSTOMER_PATH;

function path(uri, ...types) {
  if (types.indexOf("*") > -1) {
    types = [admin, corporate, customer];
  }

  var url = `/:accountType(${types.join("|")})/${uri}`;

  return url;
}

export default {
  // root: {
  //   route: {
  //     name: "root",
  //     path: "/",
  //     parent: "root",
  //     component: require("@/views/Root.vue").default,
  //     meta: {
  //       title: "Affiliate System",
  //     },
  //   },
  //   middlewares: ["auth", "password_changed"],
  //   permissions: ["*"],
  // },
  home: {
    route: {
      name: "home",
      path: "/",
      parent: "root",
      component: () =>
        import(/* webpackChunkName: 'login' */ "@/views/auth/Home.vue"),
      meta: {
        title: "CredPal Affiliate",
      },
    },
    middlewares: [],
    permissions: ["*"],
  },
  dashboard: {
    route: {
      name: "dashboard",
      // path: path('dashboard', '*'),
      path: "dashboard",
      parent: "app",
      component: require("@/views/app/dashboard/Index.vue").default,
      meta: {
        title: "Dashboard",
      },
    },
    middlewares: [
      "auth",
      "password_changed",
      // 'profile_updated',
      'profile_approved',
      // 'company_profile_updated',
      // 'company_subscribed',
    ],
    permissions: ["*"],
  },
  "daily-summary": {
    route: {
      name: "daily-summary",
      path: "daily-summary",
      parent: "app",
      component: require("@/views/app/daily-summary/Index.vue").default,
      meta: {
        title: "Daily Summary",
      },
    },
    middlewares: [
      "auth",
      "password_changed",
      // 'profile_updated',
      // 'profile_approved',
    ],
    permissions: ["*"],
  },
  conversions: {
    route: {
      name: "conversions",
      path: "conversions",
      parent: "app",
      component: require("@/views/app/conversions/Index.vue").default,
      meta: {
        title: "Conversions",
      },
    },
    middlewares: [
      "auth",
      "password_changed",
      // 'profile_updated',
      // 'profile_approved',
    ],
    permissions: ["*"],
  },
  "payment-method": {
    route: {
      name: "payment-method",
      path: "payment-method",
      parent: "app",
      component: require("@/views/app/payment-method/Index.vue").default,
      meta: {
        title: "Payment Method",
      },
    },
    middlewares: [
      "auth",
      "password_changed",
      // 'profile_updated',
      // 'profile_approved',
    ],
    permissions: ["*"],
  },
  "payment-history": {
    route: {
      name: "payment-history",
      path: "payment-history",
      parent: "app",
      component: require("@/views/app/payment-history/Index.vue").default,
      meta: {
        title: "Payment History",
      },
    },
    middlewares: [
      "auth",
      "password_changed",
      // 'profile_updated',
      // 'profile_approved',
    ],
    permissions: ["*"],
  },
  "withdrawal-request": {
    route: {
      name: "withdrawal-request",
      path: "withdrawal-request",
      parent: "app",
      component: require("@/views/app/withdrawal-request/Index.vue").default,
      meta: {
        title: "Withdrawal Request",
      },
    },
    middlewares: [
      "auth",
      "password_changed",
      // 'profile_updated',
      // 'profile_approved',
    ],
    permissions: ["*"],
  },
  "settings-password": {
    route: {
      name: "settings-password",
      path: `/settings/password`,
      parent: "app",
      component: () => import("@/views/app/settings/Password.vue"),
      meta: {
        title: "Change Your Password",
      },
    },
    middlewares: ["auth", "password_changed"],
    permissions: ["*"],
  },

  users: {
    route: {
      name: "users",
      path: path("users", admin),
      parent: "app",
      component: require("@/views/app/users/Index.vue").default,
      meta: {
        title: "Dashboard",
      },
    },
    middlewares: [
      "auth",
      "password_changed",
      "profile_updated",
      "profile_approved",
      "company_profile_updated",
      "company_subscribed",
    ],
    permissions: ["*"],
  },
  "users-new": {
    route: {
      name: "users-new",
      path: path("users/new", admin),
      parent: "app",
      component: require("@/views/app/users/new/Index.vue").default,
      meta: {
        title: "Dashboard",
      },
    },
    middlewares: [
      "auth",
      "password_changed",
      "profile_updated",
      "profile_approved",
      "company_profile_updated",
      "company_subscribed",
    ],
    permissions: ["*"],
  },

  analytics: {
    route: {
      name: "analytics",
      path: path(`analytics`, corporate),
      parent: "app",
      component: () =>
        import(
          /* webpackChunkName: 'payment-history' */ "@/views/app/analytics/Index.vue"
        ),
      meta: {
        title: "Account Summary",
      },
    },
    middlewares: [
      "auth",
      "password_changed",
      "profile_updated",
      "profile_approved",
      "company_profile_updated",
      "company_subscribed",
    ],
    permissions: ["company:can_view_analytics"],
  },
  permissions: {
    route: {
      name: "permissions",
      path: `/:accountType(${accountTypes})/permissions`,
      parent: "app",
      component: () =>
        import(
          /* webpackChunkName: 'permissions' */ "@/views/app/permissions/Index.vue"
        ),
      meta: {
        title: "Permissions",
      },
    },
    middlewares: [
      "auth",
      "password_changed",
      "company_profile_updated",
      "company_subscribed",
    ],
    permissions: ["*"],
  },

  "settings-profile": {
    route: {
      name: "settings-profile",
      path: `/:accountType(${accountTypes})/settings/profile`,
      parent: "app",
      component: () =>
        import(
          /* webpackChunkName: 'settings-profile' */ "@/views/app/settings/profile/Index.vue"
        ),
      meta: {
        title: "Update Profile",
      },
    },
    middlewares: ["auth", "password_changed"],
    permissions: [
      "personal:can_view_personal_profile",
      "personal:can_update_personal_profile",
      "personal:can_upload_personal_documents",
    ],
  },

  "settings-configuration": {
    route: {
      name: "settings-configuration",
      path: `/:accountType(${accountTypes})/settings/configuration`,
      parent: "app",
      component: () =>
        import(
          /* webpackChunkName: 'settings-configuration' */ "@/views/app/settings/Configuration.vue"
        ),
      meta: {
        title: "Configurations",
      },
    },
    middlewares: ["auth", "password_changed"],
    permissions: ["credpal:can_update_system_configuration"],
  },


  login: {
    route: {
      name: "login",
      path: "/login",
      parent: "auth",
      component: () =>
        import(/* webpackChunkName: 'login' */ "@/views/auth/Login.vue"),
      meta: {
        title: "Login To Your Account",
      },
    },
    middlewares: ["not_auth"],
    permissions: ["*"],
  },
  signup: {
    route: {
      name: "signup",
      path: "/signup",
      parent: "auth",
      component: () =>
        import(/* webpackChunkName: 'login' */ "@/views/auth/Signup2.vue"),
      meta: {
        title: "Register Now",
      },
    },
    middlewares: [],
    permissions: ["*"],
  },
  "update-password": {
    route: {
      name: "update-password",
      path: "/affiliate/password/update",
      parent: "auth",
      component: () =>
        import(
          /* webpackChunkName: 'update-password' */ "@/views/auth/UpdatePassword.vue"
        ),
      meta: {
        title: "Update Your Password",
      },
    },
    middlewares: ["auth"],
    permissions: ["*"],
  },
  "password-reset": {
    route: {
      name: "password-reset",
      path: "/affiliate/password/reset/:token",
      parent: "auth",
      component: () =>
        import(
          /* webpackChunkName: 'password-reset' */ "@/views/auth/ResetPassword.vue"
        ),
      meta: {
        title: "Reset your password",
      },
    },
    middlewares: [],
    permissions: ["*"],
  },
  "privacy": {
    route: {
      path: '/privacy-policy',
      name: 'privacy',
      parent: "root",
      component: () =>
        import(
          /* webpackChunkName: 'password-reset' */ "@/views/auth/Privacy.vue"
        ),
      meta: {
        title: "Privacy Policy",
      },
    },
    middlewares: [],
    permissions: ["*"],
  },
  "terms": {
    route: {
      path: '/terms',
      name: 'terms',
      parent: "root",
      component: () =>
        import(
          /* webpackChunkName: 'password-reset' */ "@/views/auth/Terms.vue"
        ),
      meta: {
        title: "Terms",
      },
    },
    middlewares: [],
    permissions: ["*"],
  },

  ...require("./redirects").default,
};
