<template>
  <div class="flex flex-wrap">
    <div class="w-full flex-wrap flex">
      <div class="w-full md:w-5/10 px-10 pt-16 lg:pt-10">
        <div class="border-b border-credpal-300 pb-16">
          <div class="mb-10 flex justify-between">
            <h3 class="text-2xl font-bold">My referral link</h3>
          </div>
          <div class="flex ash-color py-6 px-4 rounded">
            <div class="w-full px-2">
              <div class="flex mb-4">
                <span class="mr-4 text-sm md:hidden">Social media</span>
                <span class="mr-4 text-sm hidden md:block"
                  >To promote on social media</span
                >
              </div>
              <div
                class="flex bg-blue-200 items-center h-16 justify-between rounded px-4 py-4"
              >
                <div class="truncate w-7/10 text-sm">{{ adUrl }}</div>
                <div class="flex text-center relative">
                  <button
                    type="submit"
                    class="btn bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 text-sm mr-2 relative"
                    @click.prevent="copyCode('adUrl')"
                  >
                    <div
                      v-if="copied"
                      class="text-xs bg-white text-center rounded absolute copy-clipboard"
                    >
                      Link copied
                    </div>
                    Copy
                  </button>

                  <button
                    type="submit"
                    class="btn bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 text-sm"
                    @click.prevent="displaySocialShare"
                  >
                    Share
                  </button>

                  <div
                    class="text-xs text-center rounded absolute social-media"
                  >
                    <div
                      v-if="showSocialShare"
                      class="social-wrapper text-xs text-center rounded absolute social-media"
                    >
                      <!-- <div class=""> -->
                      <!-- <transition enter-active-class="animated bounce"> -->
                      <a :href="facebookShare" target="_blank">
                        <svg
                          width="31"
                          class="w-6 lg:w-8 cursor-pointer mb-4"
                          height="29"
                          viewBox="0 0 31 29"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M29.0625 14.5764C29.0625 7.56997 22.9896 1.88892 15.5 1.88892C8.01035 1.88892 1.9375 7.56997 1.9375 14.5764C1.9375 20.9088 6.89629 26.1577 13.3809 27.1104V18.245H9.93635V14.5764H13.3809V11.7806C13.3809 8.6014 15.4062 6.84384 18.5037 6.84384C19.9877 6.84384 21.5402 7.09192 21.5402 7.09192V10.2145H19.8291C18.1453 10.2145 17.6185 11.1921 17.6185 12.1969V14.577H21.3797L20.7791 18.2456H17.6185V27.1116C24.1037 26.1594 29.0625 20.9105 29.0625 14.5764Z"
                            fill="#3B5998"
                          />
                        </svg>
                      </a>
                      <!-- </transition> -->
                      <!-- <transition enter-active-class="animated bounce"> -->
                      <a :href="twitterShare" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 26 26" class="w-6 lg:w-8 cursor-pointer mb-4">
                          <g>
                            <polygon points="12.153992,10.729553 8.088684,5.041199 5.92041,5.041199 10.956299,12.087097 11.59021,12.97345    15.900635,19.009583 18.068909,19.009583 12.785217,11.615906  "/>
                            <path d="M21.15979,1H2.84021C1.823853,1,1,1.823853,1,2.84021v18.31958C1,22.176147,1.823853,23,2.84021,23h18.31958   C22.176147,23,23,22.176147,23,21.15979V2.84021C23,1.823853,22.176147,1,21.15979,1z M15.235352,20l-4.362549-6.213013   L5.411438,20H4l6.246887-7.104675L4,4h4.764648l4.130127,5.881958L18.06958,4h1.411377l-5.95697,6.775635L20,20H15.235352z"/>
                          </g>
                        </svg>
                      </a>
                      <!-- </transition> -->
                      <!-- <transition enter-active-class="animated bounce"> -->
                      <a :href="linkedinShare" target="_blank">
                        <svg
                          width="31"
                          class="w-6 lg:w-8 mb-4"
                          height="29"
                          viewBox="0 0 31 29"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M26.8931 1.8125H4.25523C3.01826 1.8125 1.9375 2.64512 1.9375 3.78869V25.0131C1.9375 26.1629 3.01826 27.1875 4.25523 27.1875H26.8864C28.1301 27.1875 29.0625 26.1561 29.0625 25.0131V3.78869C29.0698 2.64512 28.1301 1.8125 26.8931 1.8125ZM10.3456 22.9638H6.45975V11.6612H10.3456V22.9638ZM8.53711 9.94269H8.50926C7.26562 9.94269 6.46035 9.07666 6.46035 7.99256C6.46035 6.88863 7.28682 6.04299 8.5583 6.04299C9.82979 6.04299 10.6078 6.8824 10.6357 7.99256C10.6351 9.07666 9.82979 9.94269 8.53711 9.94269ZM24.5475 22.9638H20.6616V16.7838C20.6616 15.3032 20.0961 14.2916 18.6902 14.2916C17.6161 14.2916 16.9804 14.9713 16.6976 15.6334C16.5917 15.8713 16.5632 16.1953 16.5632 16.526V22.9638H12.6773V11.6612H16.5632V13.2341C17.1287 12.4808 18.0121 11.3967 20.0677 11.3967C22.6185 11.3967 24.5481 12.9696 24.5481 16.3606L24.5475 22.9638Z"
                            fill="#007AB9"
                          />
                        </svg>
                      </a>
                      <!-- </transition> -->
                      <!-- <transition enter-active-class="animated bounce"> -->
                      <a :href="whatsappShare" target="_blank">
                        <svg
                          width="26"
                          height="26"
                          class="w-6 lg:w-8 cursor-pointer mb-4"
                          viewBox="0 0 26 26"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.7858 0H12.7794C5.7314 0 0 5.733 0 12.7826C0 15.5788 0.901174 18.1705 2.43349 20.2748L0.840457 25.0236L5.75377 23.4529C7.77502 24.7919 10.1861 25.5652 12.7858 25.5652C19.8338 25.5652 25.5652 19.8306 25.5652 12.7826C25.5652 5.7346 19.8338 0 12.7858 0Z"
                            fill="#4CAF50"
                          />
                          <path
                            d="M20.2232 18.0508C19.9149 18.9216 18.6909 19.6438 17.7147 19.8547C17.0468 19.9969 16.1743 20.1104 13.2375 18.8928C9.48106 17.3365 7.06195 13.5193 6.8734 13.2717C6.69285 13.024 5.35547 11.2504 5.35547 9.4161C5.35547 7.5818 6.287 6.68861 6.66249 6.30513C6.97087 5.99036 7.48058 5.84656 7.96951 5.84656C8.1277 5.84656 8.2699 5.85455 8.39773 5.86094C8.77322 5.87692 8.96176 5.89929 9.20943 6.49208C9.51781 7.23507 10.2688 9.06937 10.3583 9.25792C10.4493 9.44646 10.5404 9.70211 10.4126 9.94978C10.2928 10.2054 10.1873 10.3189 9.99875 10.5362C9.81021 10.7535 9.63125 10.9197 9.44271 11.1529C9.27014 11.3559 9.07521 11.5732 9.29251 11.9487C9.50982 12.3162 10.2608 13.5417 11.3665 14.5259C12.7933 15.7962 13.9502 16.2021 14.364 16.3746C14.6724 16.5025 15.0399 16.4721 15.2652 16.2324C15.5512 15.924 15.9043 15.4127 16.2638 14.9094C16.5195 14.5483 16.8422 14.5036 17.181 14.6314C17.5261 14.7512 19.3524 15.654 19.7279 15.841C20.1034 16.0295 20.3511 16.119 20.4421 16.2772C20.5316 16.4354 20.5316 17.1783 20.2232 18.0508Z"
                            fill="#FAFAFA"
                          />
                        </svg>
                      </a>
                      <!-- </transition> -->
                    </div>
                  </div>
                  <input type="hidden" id="adUrl" :value="adUrl" />
                </div>
              </div>
            </div>
            <!-- <div class="w-5/10 px-2">
              <div class="flex mb-4">
                <span class="mr-4 text-sm md:hidden">via website</span>
                <span class="mr-4 hidden text-sm md:block"
                  >To promote via website</span
                >
                <span
                  class="rounded-full h-4 w-4 text-sm flex items-center justify-center info text-white"
                  >i</span
                >
              </div>
              <div
                class="flex bg-blue-200 items-center h-16 justify-between rounded px-4 py-4"
              >
                <div class="truncate w-7/10 text-sm">{{ embeddedUrl }}</div>
                <div class="text-center">
                  <button
                    type="submit"
                    class="btn bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 text-sm"
                  >
                    Copy
                  </button>
                  <input type="hidden" id="embeddedUrl" :value="embeddedUrl" />
                </div>
              </div>
            </div> -->
          </div>
        </div>

        <div class="w-full hidden md:block">
          <div class="pb-12 pt-6 mt-6">
            <div class="flex items-center justify-between">
              <h3 class="text-2xl font-bold mr-2">Recent referrals</h3>
              <router-link
                v-if="totalLeads"
                :to="{ name: 'conversions' }"
                class="btn btn-sm btn-gray-soft"
              >
                View All
              </router-link>
            </div>

            <div
              class="flex py-4 px-4 rounded mt-4 mb-6 justify-between"
              style="background-color: #F5F4FC;"
            >
              <div class="flex">
                <div class="w-12">
                  <span
                    class="rounded-full h-8 w-8 mr-2 text-xl text-bold flex items-center justify-center text-white bg-purple-500"
                    >i</span
                  >
                </div>
                <div class="self-center text-xs xl:text-sm">
                  Making more money with CredPal
                </div>
              </div>
              <div class="">
                <button
                  type="submit"
                  class="btn bg-purple-500 hover:bg-purple-600 text-white px-2 py-2 text-xs xl:text-sm"
                  @click.prevent="openInformationModal"
                >
                  Learn More
                </button>
              </div>
            </div>

            <div class="md:-mt-8">
              <datatable
                :data="conversions.data"
                :columns="conversions.columns"
                :paginatable="false"
                :limit="5"
                :loading="conversions.loading"
                :showHeadings="false"
                :fillable="false"
                table-style="flush"
                v-if="totalLeads"
                ref="table"
              />
              <!-- <datatable2
                :url="url"
                :ajax="false"
                :ajaxPagination="false"
                :fillable="false"
                :columns="conversions.columns"
                :loading="conversions.loading"
                table-style="flush"
                :limit="5"
                ref="table"
              /> -->
              <div
                v-if="!totalLeads"
                class="pt-8 pb-8 w-full bg-gray-300 rounded text-center"
              >
                <div
                  class="rounded-full h-12 w-12 mb-4 text-2xl bg-purple-500 flex items-center justify-center m-auto text-white"
                >
                  <ion-icon name="gift"></ion-icon>
                </div>
                <div class="text-gray-600 text-sm">
                  <p>
                    You have not referred anyone yet.
                  </p>
                  <p>
                    Share your link and begin to earn.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="w-full md:w-5/10 px-8 xl:px-18 pt-10 md:border-l border-credpal-300"
      >
        <div>
          <div class="mb-10 flex justify-between">
            <h3 class="text-2xl font-bold">My wallet</h3>
          </div>
          <sm-loader v-if="rewards.loading" />
          <div v-else class="flex justify-between ash-color py-8 px-8 rounded">
            <!-- <div class="w-5/10" :class="totalLeads ? 'w-5/10':'w-5/10'"> -->
            <div class="" :class="totalLeads ? 'w-5/10' : 'w-full'">
              <div class="mb-4">
                <span class="ash-color-font mr-4 text-sm">Earned balance</span>
                <ion-icon name="wallet" class="text-blue-500"></ion-icon>
              </div>
              <div class="justify-between">
                <div class="mt-4 text-2xl font-bold" v-if="totalLeads">
                  ₦ {{ totalConversions | currency }}
                </div>
                <div
                  class="mt-4 text-2xl font-bold md:w-5/10"
                  v-if="!totalLeads"
                >
                  ₦ 0.00
                </div>
                <div class="flex md:w-full" v-if="!totalLeads">
                  <div class="">
                    <span
                      class="rounded-full h-8 w-8 mb-4 mr-2 text-xl bg-green-200 flex items-center justify-center text-green-500"
                      >!</span
                    >
                  </div>
                  <span class="text-black-500 font-hairline text-xs mt-2"
                    >Start sharing your referral link to start earning.</span
                  >
                </div>
              </div>
            </div>
            <div
              class="w-5/10 border-l border-credpal-300 pl-8"
              v-if="totalLeads"
            >
              <div class="mb-4 flex">
                <span class="ash-color-font mr-4 text-sm">Processing</span>
                <img :src="processing" alt="" />
              </div>
              <div class="justify-between">
                <div class="mt-4 text-2xl font-bold" v-if="totalLeads">
                  ₦ {{ totalProcessing | currency }}
                </div>
                <div class="mt-4 text-2xl font-bold" v-if="!totalLeads">
                  ₦ 0.00
                </div>
              </div>
            </div>
          </div>
          <div class="flex mt-10 justify-between">
            <button
              class="px-2 py-2 rounded-sm text-xs md:text-sm text-white bg-green-500"
              @click.prevent="openWithdrawModal"
            >
              <ion-icon name="business" class="mr-2"></ion-icon>
              Withdraw Earnings
            </button>
            <router-link
              :to="{ name: 'payment-history' }"
              class="btn bg-gray-200 px-10"
              style="color: #6E7AB6;"
            >
              Payout history
            </router-link>
          </div>
        </div>

        <div class="w-full hidden md:block">
          <div
            class="pb-12 lg:border-t border-t-2 pt-10 mt-6 border-credpal-300"
          >
            <h3 class="text-2xl font-bold mr-2 mb-8">Account Stats</h3>
            <div class="ash-color px-6 py-4 rounded flex">
              <div class="w-4/12">
                <div class="mb-2">
                  <span class="ash-color-font mr-2 text-sm">Leads</span>
                  <ion-icon
                    name="people"
                    class="text-red-500 text-sm"
                  ></ion-icon>
                </div>
                <div class="mt-2">{{ totalLeads }}</div>
              </div>
              <div class="border-l border-credpal-300 pl-4 w-4/12">
                <div class="mb-2">
                  <span class="ash-color-font mr-2 text-sm">Converted</span>
                  <ion-icon
                    name="person-add"
                    class="text-blue-500 text-sm"
                  ></ion-icon>
                </div>
                <div class="mt-2">{{ totalConverted }}</div>
              </div>
              <div class="w-4/12 border-l border-credpal-300 pl-4">
                <div class="mb-2">
                  <span class="ash-color-font mr-2 text-sm">Paid out</span>
                  <ion-icon
                    name="business"
                    class="text-purple-500 text-sm"
                  ></ion-icon>
                </div>
                <div class="mt-2">₦ {{ totalDisbursed }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="px-10 w-full">
        <div
          class="pb-12 lg:border-t border-t-2 pt-10 mt-6 border-credpal-300 md:hidden"
        >
          <div class="flex items-center justify-between">
            <h3 class="text-2xl font-bold mr-2">Recent referrals</h3>
            <router-link
              v-if="totalLeads"
              :to="{ name: 'payment-history' }"
              class="btn btn-sm btn-gray-soft"
            >
              View All
            </router-link>
          </div>

          <div
            class="flex py-4 px-4 rounded mt-4 mb-6 justify-between"
            style="background-color: #F5F4FC;"
          >
            <div class="flex">
              <div class="w-12">
                <span
                  class="rounded-full h-8 w-8 mr-2 text-xl text-bold flex items-center justify-center text-white bg-purple-500"
                  >i</span
                >
              </div>
              <div class="self-center text-xs xl:text-sm">
                Making more money with CredPal
              </div>
            </div>
            <div class="">
              <button
                type="submit"
                class="btn bg-purple-500 hover:bg-purple-600 text-white px-2 py-2 text-xs xl:text-sm"
                @click.prevent="openInformationModal"
              >
                Learn More
              </button>
            </div>
          </div>

          <div class="lg:w-10/12">
            <datatable
              :data="conversions.data"
              :columns="conversions.columns"
              :limit="5"
              :loading="conversions.loading"
              :paginatable="false"
              :showHeadings="false"
              :fillable="false"
              table-style="flush"
              v-if="totalLeads"
              ref="table"
            />
            <!-- <datatable2
              :url="url"
              :ajax="true"
              :ajaxPagination="true"
              :fillable="false"
              :columns="conversions.columns"
              :loading="conversions.loading"
              table-style="flush"
              :limit="5"
              ref="table"
            /> -->
            <div
              v-if="!totalLeads"
              class="pt-8 pb-8 w-full bg-gray-300 rounded text-center"
            >
              <div
                class="rounded-full h-12 w-12 mb-4 text-2xl bg-purple-500 flex items-center justify-center m-auto text-white"
              >
                <ion-icon name="gift"></ion-icon>
              </div>
              <div class="text-gray-600 text-sm">
                <p>
                  You have not referred anyone yet.
                </p>
                <p>
                  Share your link and begin to earn.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="px-10 w-full">
        <div
          class="pb-12 lg:border-t border-t-2 pt-10 mt-6 border-credpal-300 md:hidden"
        >
          <h3 class="text-2xl font-bold mr-2 mb-8">Account Stats</h3>
          <div class="ash-color px-6 py-4 rounded flex">
            <div class="w-4/12">
              <div class="mb-2">
                <span class="ash-color-font mr-2 text-sm">Leads</span>
                <ion-icon name="people" class="text-red-500 text-sm"></ion-icon>
              </div>
              <div class="mt-2">{{ totalLeads }}</div>
            </div>
            <div class="border-l border-credpal-300 pl-4 w-4/12">
              <div class="mb-2">
                <span class="ash-color-font mr-2 text-sm">Converted</span>
                <ion-icon
                  name="person-add"
                  class="text-blue-500 text-sm"
                ></ion-icon>
              </div>
              <div class="mt-2">{{ totalConverted }}</div>
            </div>
            <div class="w-4/12 border-l border-credpal-300 pl-4">
              <div class="mb-2">
                <span class="ash-color-font mr-2 text-sm">Payed out</span>
                <ion-icon
                  name="business"
                  class="text-purple-500 text-sm"
                ></ion-icon>
              </div>
              <div class="mt-2">₦{{ totalDisbursed | currency }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal
      className="w-11/12 md:w-2/5 xl:w-3/10 pt-10"
      ref="withdrawConfirmationModal"
    >
      <h4 class="text-xl font-bold mb-6">Confirm Withdraw</h4>

      <!-- <template v-if="getFormError(withdrawForm)">
        <div class="alert alert-red-soft mb-16">
          <div class="alert-icon">!</div>
          <span>{{ getFormError(withdrawForm) }}</span>
        </div>
      </template> -->

      <div class="mb-6">Are you sure you want to withdraw?</div>

      <div class="bg-blue-200 rounded p-6 text-center mb-4">
        <div class="text-2xl font-bold">₦{{ totalConversions | currency }}</div>
        <div class="text-sm text-gray-600">Amount</div>
      </div>

      <div class="border border-dashed border-blue-200 rounded p-6 mb-4">
        <div class="font-bold mb-4">{{ accountName }}</div>

        <div class="grid grid-cols-2 gap-3 mb-4">
          <div class="col-span-2">
            <div class="bg-blue-200 p-4 flex items-center rounded">
              <ion-icon
                name="wallet-outline"
                class="text-blue-500 mr-2"
              ></ion-icon>
              <span class="text-xs">{{ accountNumber }}</span>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-3 mb-4">
          <div class="col-span-2">
            <div class="bg-blue-200 p-4 flex items-center rounded">
              <ion-icon
                name="business-outline"
                class="text-blue-500 mr-2"
              ></ion-icon>
              <span class="text-xs">{{ bankName }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-row items-center">
        <button
          type="button"
          class="btn btn-gray-soft btn-md mr-2"
          @click.prevent="closeWithdrawModal"
          :disabled="withdrawal.loading"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-blue btn-md w-full"
          @click.prevent="withdrawEarning"
          :disabled="withdrawal.loading"
        >
          <span v-if="withdrawal.loading">Verifying</span>
          <span v-else>Confirm</span>
        </button>
      </div>
    </modal>

    <modal
      className="w-3/5 xl:w-3/10 text-center pt-24"
      ref="uploadBankDetailsModal"
    >
      <img
        :src="assets.icons.lg.exclamation"
        class="mx-auto mb-8"
        height="50"
        alt="Check Mark"
      />

      <div class="text-2xl mb-10">
        {{ modalMessage }}
      </div>

      <router-link
        :to="{ name: 'payment-method' }"
        class="button bg-blue-500 hover:bg-blue-600 text-white"
        v-if="modalMessage === 'Please upload your bank details to withdraw'"
      >
        Go to Bank Details
      </router-link>
    </modal>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 text-center pt-24"
      ref="successModal"
    >
      <img
        :src="assets.icons.lg.checkmark"
        class="mx-auto mb-8"
        height="90"
        alt="Check Mark"
      />

      <div class="text-lg font-bold mb-4">
        Success!
      </div>
      <div class="text-sm mb-10">
        Withdawal request sent successfully.
      </div>

      <button
        type="button"
        class="btn btn-blue"
        @click.prevent="$refs.successModal.close"
      >
        Okay
      </button>
    </modal>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 text-center pt-24"
      ref="errorModal"
    >
      <img
        :src="assets.icons.lg.exclamation"
        class="mx-auto mb-8"
        height="50"
        alt="Check Mark"
      />

      <div class="text-lg font-bold mb-4">
        Error!
      </div>
      <div class="text-sm mb-10">
        There was an issue requesting for your earned balance, please can you
        contact the customer service
      </div>

      <button
        type="button"
        class="btn btn-blue"
        @click.prevent="$refs.errorModal.close"
      >
        Okay
      </button>
    </modal>
    <modal
      className="w-11/12 md:w-7/10 xl:w-4.7/10 text-center pt-16  px-6 md:px-16"
      ref="informationModal"
    >
      <div class="text-xl md:text-2xl text-left font-bold mb-4">
        <div>
          Welcome <span>{{ fName }}</span>
        </div>
        <p class="text-blue-500">
          When someone signs up on credpal through your referral link and successfully makes an investment you can earn as much as 4%.
        </p>
      </div>
      <div class="text-sm mb-10">
        <div class="flex border border-credpal-300 rounded px-8 py-6">
          <div
            class="bg-blue-500 image-holder rounded flex justify-center mr-6"
          >
            <img :src="money" alt="" />
          </div>
          <div class="text-left w-8/12 lg:w-8/10">
            <h4 class="text-blue-500 font-bold">How to make money fast</h4>
            <div class="news-list-text">
              Your friends and family are a good place to start. Everyone has a
              family, friend and neighbour that's working. Start there and make
              your first cash.
            </div>
          </div>
        </div>

        <div class="flex border border-credpal-300 rounded px-8 py-6 my-6">
          <div
            class="bg-blue-500 image-holder rounded flex justify-center mr-6"
          >
            <img :src="heart" alt="" />
          </div>
          <div class="text-left w-8/10">
            <h4 class="text-blue-500 font-bold">Making at least N100k/month</h4>
            <div class="news-list-text">
              CredPal can be your stable source of income. Share your link to a
              larger audience on Whatsapp, Twitter, Facebook, Church members,
              etc
            </div>
          </div>
        </div>

        <div class="flex border border-credpal-300 rounded px-8 py-6 my-6">
          <div
            class="bg-blue-500 image-holder rounded flex justify-center mr-6"
          >
            <ion-icon
              name="wallet-outline"
              class="text-white text-2xl self-center"
            ></ion-icon>
          </div>
          <div class="text-left w-8/10">
            <h4 class="text-blue-500 font-bold">
              What you will earn
            </h4>
            <div class="news-list-text">
              When someone signs up on CredPal through your referral link, you
              earn 1,000 Naira per credit card and as much as 2.8% for loan
              approvals.
            </div>
          </div>
        </div>

        <div class="flex border border-credpal-300 rounded px-8 py-6">
          <div
            class="bg-blue-500 image-holder rounded flex justify-center mr-6"
          >
            <img :src="bag" alt="" />
          </div>
          <div class="text-left w-8/10">
            <h4 class="text-blue-500 font-bold">Cashing out</h4>
            <div class="news-list-text">
              Cashing out is the easiest part. Simply withdraw and the money
              goes straight into your account. No minimum amount to cash out.
            </div>
          </div>
        </div>
      </div>

      <button
        type="button"
        class="btn btn-blue text-xl"
        @click.prevent="$refs.informationModal.close"
      >
        Okay, let’s go!
      </button>
    </modal>

    <modal
      className="w-11/12 md:w-7/10 xl:w-4.7/10 text-center pt-16"
      ref="socialModal"
    >
      <div class="bg-blue-200">
        <div class="flex flex-row mb-10 py-10 px-8">
          <a :href="facebookShare" target="_blank">
            <svg
              width="31"
              class="w-6 lg:w-8 cursor-pointer"
              height="29"
              viewBox="0 0 31 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M29.0625 14.5764C29.0625 7.56997 22.9896 1.88892 15.5 1.88892C8.01035 1.88892 1.9375 7.56997 1.9375 14.5764C1.9375 20.9088 6.89629 26.1577 13.3809 27.1104V18.245H9.93635V14.5764H13.3809V11.7806C13.3809 8.6014 15.4062 6.84384 18.5037 6.84384C19.9877 6.84384 21.5402 7.09192 21.5402 7.09192V10.2145H19.8291C18.1453 10.2145 17.6185 11.1921 17.6185 12.1969V14.577H21.3797L20.7791 18.2456H17.6185V27.1116C24.1037 26.1594 29.0625 20.9105 29.0625 14.5764Z"
                fill="#3B5998"
              />
            </svg>
          </a>
          <a :href="twitterShare" target="_blank">
            <svg
              width="30"
              height="30"
              class="w-4 lg:w-5 cursor-pointer"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M29.2773 6.12757C28.2223 6.58598 27.1061 6.88799 25.9639 7.02406C27.1643 6.32116 28.067 5.20447 28.5027 3.88343C27.3678 4.54706 26.1283 5.01269 24.8371 5.26039C24.2934 4.69058 23.6395 4.23728 22.9152 3.92805C22.1909 3.61881 21.4112 3.4601 20.6236 3.46156C17.435 3.46156 14.8545 6.00453 14.8545 9.13929C14.8522 9.57533 14.9022 10.0101 15.0033 10.4342C12.7168 10.327 10.4778 9.74374 8.42968 8.72167C6.38151 7.6996 4.56923 6.26126 3.10879 4.49867C2.59643 5.36241 2.32547 6.34791 2.32422 7.35218C2.32422 9.32093 3.35137 11.0612 4.90234 12.0807C3.98343 12.0589 3.08335 11.8157 2.27852 11.3717V11.442C2.27852 14.1959 4.2707 16.4869 6.90742 17.0084C6.41159 17.1406 5.90065 17.2076 5.3875 17.2077C5.02339 17.2083 4.6601 17.173 4.30293 17.1022C5.03594 19.358 7.16934 20.9987 9.69648 21.0455C7.643 22.6281 5.12185 23.4835 2.5293 23.4772C2.06911 23.4765 1.60935 23.4491 1.15234 23.3952C3.78975 25.0793 6.85553 25.9701 9.98477 25.9616C20.6113 25.9616 26.4168 17.3073 26.4168 9.8014C26.4168 9.55531 26.4104 9.30921 26.3986 9.06898C27.5254 8.26745 28.5003 7.27139 29.2773 6.12757Z"
                fill="#55ACEE"
              />
            </svg>
          </a>
          <a :href="linkedinShare" target="_blank">
            <svg
              width="31"
              class="w-4 lg:w-5"
              height="29"
              viewBox="0 0 31 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M26.8931 1.8125H4.25523C3.01826 1.8125 1.9375 2.64512 1.9375 3.78869V25.0131C1.9375 26.1629 3.01826 27.1875 4.25523 27.1875H26.8864C28.1301 27.1875 29.0625 26.1561 29.0625 25.0131V3.78869C29.0698 2.64512 28.1301 1.8125 26.8931 1.8125ZM10.3456 22.9638H6.45975V11.6612H10.3456V22.9638ZM8.53711 9.94269H8.50926C7.26562 9.94269 6.46035 9.07666 6.46035 7.99256C6.46035 6.88863 7.28682 6.04299 8.5583 6.04299C9.82979 6.04299 10.6078 6.8824 10.6357 7.99256C10.6351 9.07666 9.82979 9.94269 8.53711 9.94269ZM24.5475 22.9638H20.6616V16.7838C20.6616 15.3032 20.0961 14.2916 18.6902 14.2916C17.6161 14.2916 16.9804 14.9713 16.6976 15.6334C16.5917 15.8713 16.5632 16.1953 16.5632 16.526V22.9638H12.6773V11.6612H16.5632V13.2341C17.1287 12.4808 18.0121 11.3967 20.0677 11.3967C22.6185 11.3967 24.5481 12.9696 24.5481 16.3606L24.5475 22.9638Z"
                fill="#007AB9"
              />
            </svg>
          </a>
          <a :href="whatsappShare" target="_blank">
            <svg
              width="26"
              height="26"
              class="w-4 lg:w-5 cursor-pointer"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7858 0H12.7794C5.7314 0 0 5.733 0 12.7826C0 15.5788 0.901174 18.1705 2.43349 20.2748L0.840457 25.0236L5.75377 23.4529C7.77502 24.7919 10.1861 25.5652 12.7858 25.5652C19.8338 25.5652 25.5652 19.8306 25.5652 12.7826C25.5652 5.7346 19.8338 0 12.7858 0Z"
                fill="#4CAF50"
              />
              <path
                d="M20.2232 18.0508C19.9149 18.9216 18.6909 19.6438 17.7147 19.8547C17.0468 19.9969 16.1743 20.1104 13.2375 18.8928C9.48106 17.3365 7.06195 13.5193 6.8734 13.2717C6.69285 13.024 5.35547 11.2504 5.35547 9.4161C5.35547 7.5818 6.287 6.68861 6.66249 6.30513C6.97087 5.99036 7.48058 5.84656 7.96951 5.84656C8.1277 5.84656 8.2699 5.85455 8.39773 5.86094C8.77322 5.87692 8.96176 5.89929 9.20943 6.49208C9.51781 7.23507 10.2688 9.06937 10.3583 9.25792C10.4493 9.44646 10.5404 9.70211 10.4126 9.94978C10.2928 10.2054 10.1873 10.3189 9.99875 10.5362C9.81021 10.7535 9.63125 10.9197 9.44271 11.1529C9.27014 11.3559 9.07521 11.5732 9.29251 11.9487C9.50982 12.3162 10.2608 13.5417 11.3665 14.5259C12.7933 15.7962 13.9502 16.2021 14.364 16.3746C14.6724 16.5025 15.0399 16.4721 15.2652 16.2324C15.5512 15.924 15.9043 15.4127 16.2638 14.9094C16.5195 14.5483 16.8422 14.5036 17.181 14.6314C17.5261 14.7512 19.3524 15.654 19.7279 15.841C20.1034 16.0295 20.3511 16.119 20.4421 16.2772C20.5316 16.4354 20.5316 17.1783 20.2232 18.0508Z"
                fill="#FAFAFA"
              />
            </svg>
          </a>
        </div>
      </div>

      <button
        type="button"
        class="btn btn-blue text-xl"
        @click.prevent="$refs.informationModal.close"
      >
        Okay, let’s go!
      </button>
    </modal>

  </div>
</template>

<script>
export default {
  data() {
    return {
      processing: require("@/assets/icons/processing.svg"),
      money: require("@/assets/icons/money.svg"),
      bag: require("@/assets/icons/money-bag.svg"),
      heart: require("@/assets/icons/heart.svg"),
      twitter: require("@/assets/social/twitter.svg"),
      selectedRepayments: null,
      rewards: this.$options.resource({}),
      table: this.$options.resource([], {
        selected: null,
      }),
      conversions: this.$options.resource([], {
        loading: false,
        columns: [
          {
            name: "customer_name",
            th: "Customer Name",
            className: "w-full whitespace-normal",
            render: (conversions) => {
              if (!conversions.owner) {
                return "---";
              }
              return `${conversions.owner.name} ${conversions.owner.last_name}`;
            },
          },
          {
            align: "right",
            name: "status",
            th: "Status",
            render: (conversions, status) => {
              // const status = conversions.reward?.status ?? "not_converted";
              switch (status) {
                case "approved":
                  return `<div class="badge badge-green-soft-outline">
											Approved
										</div>`;
                case "declined":
                  return `<div class="badge badge-red-soft-outline">
											Rejected
                    </div>`;
                case "incomplete-signup":
                  return `<div class="badge badge-orange-soft-outline">
											Incomplete Signup
										</div>`;
                case "processing":
                  return `<div class="badge badge-orange-soft-outline">
											Processing
										</div>`;
                case "pending":
                default:
                  return `<div class="badge badge-orange-soft-outline">
											Pending
										</div>`;
              }
            },
          },
        ],
      }),
      filterForm: this.$options.basicForm([
        { name: "from", rules: "required" },
        { name: "to", rules: "required" },
      ]),
      links: null,
      meta: null,
      pages: [],
      embeddedUrl: "",
      adUrl: "",
      profile: null,
      withdrawal: this.$options.resource([], {
        loading: false,
      }),
      modalMessage: "",
      detail: null,
      fName: "",
      copied: false,
      shareDescription:
        "Never miss an opportunity with a CredPal Credit Card. Sign up today!",
      showSocialShare: false,
      url: `${this.$baseurl}/affiliate/customers`,
      loading: false,
      becomeLeaderResponseMessage: null,
      responseFail: false,
      userMedium: "",
      affiliateCode: ""
    };
  },
  computed: {
    accountNumber() {
      return this.profile?.account_no || "";
    },
    accountName() {
      return this.profile?.account_name || "";
    },
    bankName() {
      return this.profile?.bank_name || "";
    },
    totalLeads() {
      return this.rewards.data.total_leads || 0;
    },
    totalConverted() {
      return (
        this.rewards.data.total_leads -
          this.rewards.data.total_incomplete_signup || 0
      );
    },
    totalConversions() {
      return this.$options.filters.currency(
        this.rewards.data.total_earned_rewards || 0
      );
    },
    totalDisbursed() {
      return this.$options.filters.currency(
        this.rewards.data.total_disbursed_rewards || 0
      );
    },
    totalProcessing() {
      return this.$options.filters.currency(
        this.rewards.data.total_processing_rewards || 0
      );
    },
    totalToReceive() {
      const amountToWithdraw = parseInt(
        this.rewards?.data?.total_earned_rewards
      );

      const transferFee = amountToWithdraw < 2500.0 ? 0.0 : 100.0;
      const sum = amountToWithdraw * 0.015 + transferFee;
      const transferCharge = sum < 2000 ? sum : 2000;
      return this.$options.filters.currency(
        amountToWithdraw - transferCharge || 0
      );
    },
    twitterShare() {
       const url = `${this.$frontEndBaseurl}/a/m?ac%3D${this.affiliateCode}%26me%3D${this.userMedium}`
      return `https://twitter.com/intent/tweet?text=Never miss an opportunity with a CredPal Credit Card. Sign up today!.${
        url
      }&hashtags=loans,creditCard,money,cash`;
    },
    facebookShare() {
      const url = `${this.$frontEndBaseurl}/a/m?ac%3D${this.affiliateCode}%26me%3D${this.userMedium}`
      return `https://www.facebook.com/sharer/sharer.php?u=${url}&description=Never miss an opportunity with a CredPal Credit Card. Sign up today!&quote=Never miss an opportunity with a CredPal Credit Card. Sign up today!. ${
        url
      }&quote=${url}`;
    },
    linkedinShare() {
      const url = `${this.$frontEndBaseurl}/a/m?ac%3D${this.affiliateCode}%26me%3D${this.userMedium}`
      return `https://www.linkedin.com/sharing/share-offsite/?url=${url}`;
    },
    whatsappShare() {
      const url = `${this.$frontEndBaseurl}/a/m?ac%3D${this.affiliateCode}%26me%3D${this.userMedium}`
      return `https://wa.me/?text=${this.shareDescription}%0D%0A${
        url
      }`;
    },
    isOriginator() {
      return !!this.user?.profile?.originator_at;
    },
  },
  async beforeMount() {
    this.getTotalRewards();
    this.getConversions();
    this.getReferralCodes();
    this.getBankDetails();
  },
  mounted() {
    this.$options.listenForClick.apply(this);
    this.detail = this.$store.state.session.user;

    if (this.detail?.first_name) {
      this.fName =
        this.detail.first_name
          .toLowerCase()
          .charAt(0)
          .toUpperCase() + this.detail.first_name.toLowerCase().slice(1);

      const newsModal = localStorage.getItem("newsModal");
      if (!newsModal) {
        localStorage.setItem("newsModal", JSON.stringify("modalOpened"));
        this.openInformationModal();
      }
    }
  },
  methods: {
    displaySocialShare() {
      this.showSocialShare = !this.showSocialShare;
    },
    openSocialModal() {
      this.$refs.socialModal.open();
    },
    closeSocialModal() {
      this.$refs.socialModal.close();
    },
    openInformationModal() {
      this.$refs.informationModal.open();
    },
    closeInformationModal() {
      this.$refs.informationModal.close();
    },
    openUploadBankDetailsModal() {
      this.$refs.uploadBankDetailsModal.open();
    },
    closeUploadBankDetailsModal() {
      this.$refs.uploadBankDetailsModal.close();
    },
    openBecomeLegendModal() {
      this.$refs.becomeLegendModal.open();
    },
    closeBecomeLegendModal() {
      this.$refs.becomeLegendModal.close();
    },
    openWithdrawModal() {
      if (!this.profile) {
        this.modalMessage = "Please upload your bank details to withdraw";
        this.openUploadBankDetailsModal();
        return;
      }
      if (!this.rewards.data.total_earned_rewards) {
        this.modalMessage = "You have no money in your wallet";
        this.openUploadBankDetailsModal();
        return;
      }
      this.$refs.withdrawConfirmationModal.open();
    },
    closeWithdrawModal() {
      this.$refs.withdrawConfirmationModal.close();
    },
    copyCode(type) {
      this.copied = true;
      let url;
      if (type === "adUrl") {
        url = document.getElementById("adUrl");
      } else {
        url = document.getElementById("embeddedUrl");
      }

      url.setAttribute("type", "text");
      url.select();
      url.setSelectionRange(0, 99999);
      document.execCommand("copy");
      url.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();

      setTimeout(() => {
        this.copied = false;
      }, 2000);
    },
    async getTotalRewards() {
      this.rewards.loading = true;
      await this.sendRequest("affiliate.rewards.total", {
        success: (response) => {
          this.rewards.data = {
            ...response.data.data,
          };
        },
        error: (error) => {
          this.rewards.error = error;
        },
      });
      this.rewards.loading = false;
    },
    async getConversions() {
      this.conversions.loading = true;
      await this.sendRequest("affiliate.conversions.customers", {
        success: (response) => {
          // const { data, links, meta } = response.datatatble.data;
          // this.links = links;
          // this.meta = meta;
          // this.pages = new Array(meta.last_page);
          this.conversions.data = response.data.datatable.data.slice(0, 4);
        },
        error: (error) => {
          this.conversions.error = error;
        },
      });
      this.conversions.loading = false;
    },
    async filterCustomers() {
      await this.$get({
        url: `${this.$baseurl}/affiliate/customers?from=2020-05-05&to=2020-11-20`,
        headers: this.headers,
        success: () => {},
        error: () => {
          this.topUpError = "Top up failed";
        },
      });
    },
    async nextPage() {
      await this.$get({
        url: `${this.links.next}`,
        headers: this.headers,
        success: (response) => {
          const { data, links, meta } = response.data.data;
          this.links = links;
          this.meta = meta;
          this.pages = new Array(meta.last_page);
          this.conversions.data = data.slice(0, 4);
        },
        error: () => {},
      });
    },
    async getData(pageNumber) {
      await this.$get({
        url: `${this.$baseurl}/affiliate/customers?page=${pageNumber}`,
        headers: this.headers,
        success: (response) => {
          const { data, links, meta } = response.data.data;
          this.links = links;
          this.meta = meta;
          this.pages = new Array(meta.last_page);
          this.conversions.data = data.slice(0, 4);
        },
        error: () => {},
      });
    },
    async getReferralCodes() {
      this.loading = true;
      await this.sendRequest("affiliate.referralCodes.codes", {
        success: (response) => {
          const {
            embed_dynamic_link,
            // social_dynamic_link,
            code,
            embed,
          } = response.data.data.data[0];
          this.affiliateCode = code;
          this.userMedium = embed;
          this.embeddedUrl = embed_dynamic_link;
          this.adUrl = `${this.$frontEndBaseurl}/a/m?ac=${code}&me=${embed}`;
        },
        error: (error) => {
          this.error = error;
        },
      });
      this.loading = false;
    },
    async getBankDetails() {
      this.loading = true;
      await this.sendRequest("affiliate.payment.getBankDetails", {
        success: (response) => {
          const { data } = response.data;
          if (typeof data === "object" && data?.bank_name) {
            this.profile = data;
          }
        },
        error: (error) => {
          this.error = error;
        },
      });
      this.loading = false;
    },
    async withdrawEarning() {
      this.withdrawal.loading = true;
      await this.$get({
        url: `${this.$baseurl}/affiliate/rewards/request-to-withdraw`,
        headers: this.headers,
        success: () => {
          this.closeWithdrawModal();
          this.getTotalRewards();
          this.$refs.successModal.open();
        },
        error: () => {
          this.$refs.errorModal.open();
        },
      });

      this.withdrawal.loading = false;
    },
    async becomeLeader() {
      this.loading = true;
      const { response } = await this.$get({
        url: `${this.$baseurl}/affiliate/originators/upgrade`,
        headers: this.headers,
      });

      if (response.data.success) {
        this.closeBecomeLegendModal();
        this.responseFail = false;
        this.becomeLeaderResponseMessage = response.data.message;
        this.$refs.becomeLeaderResponseModal.open();
      } else {
        this.closeBecomeLegendModal();
        this.responseFail = true;
        this.becomeLeaderResponseMessage = response.data.message;
        this.$refs.becomeLeaderResponseModal.open();
      }
      this.$root.loadSession();

      this.loading = false;
    },
  },
};
</script>

<style scoped>
.info {
  background: #8289a3;
}

.ash-color {
  background-color: #f4f6fa;
}

.ash-color-font {
  color: #636a7b;
}

.copy-clipboard {
  padding: 0.5rem;
  bottom: 119%;
  width: 80px;
  color: black;
}
.copy-clipboard::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
  --bg-opacity: 1;
}
.image-holder {
  height: 48px;
  width: 48px;
}
.news-list-text {
  color: #111c46;
  font-family: SF UI Text;
  opacity: 0.7;
}

.social-media {
  padding: 0.5rem;
  /* right: -100%; */

  right: -50%;
  bottom: -90%;
}
.social-media::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
  --bg-opacity: 1;
}
@media only screen and (max-width: 768px) {
  .social-media {
    right: -30%;
  }
}
</style>
